
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../constants";

export function LanguageDirection() {
    const { i18n } = useTranslation();
    const selectedLang = i18n.language;
    const languages = LANGUAGES;

    // return language direction
    if (selectedLang)
        return languages.filter(lang => lang.code == selectedLang)[0]?.directionality
    else return 'ltr'
}
