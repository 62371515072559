import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: null,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        SetUser: (state, action) => {
            state.user = action.payload;
        },
        updateUserPoints: (state, action) => {
            if (state.user) {
                state.user.rewardPoints = String(action.payload);
            }
        },
        logout: (state) => {
            state.user = null;
        },
    },
});

export const { SetUser, updateUserPoints, logout } = authSlice.actions;
export default authSlice.reducer;
