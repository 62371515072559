import axios from "axios";
import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import { LanguageDirection } from "../../utility/LanguageDirection";
import { SingleLoading } from "../LoadingPage/LoadingPage";
import Text from "../Text/Text";
import Title from "../Title/Title";
import "./OTP.css";
import { useVerifyOTPMutation } from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import { SetUser } from "../../features/auth/authReducer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OTP = (props) => {
  const { state } = useLocation();
  const otpCode = queryString?.parse(window.location.search)?.otp;
  const phone = queryString?.parse(window.location.search)?.phone;
  const direction = LanguageDirection();
  const { t } = useTranslation();

  const [otp, setOtp] = useState(['', '', '', '']);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [countdown, setCountdown] = useState(300);
  const [verifyOTP] = useVerifyOTPMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const Otpuser = async (otpData) => {
    try {
      const resp = await verifyOTP({ otp: otpData, phone: phone, otpId: state });
  
      if (resp.data) {
        const user = resp.data;
        if (user?.name && user?.metadata?.occupation) {
          dispatch(SetUser(resp.data));
          navigate('/home/reward');
        } else {
          dispatch(SetUser(resp.data));
          navigate('/auth');
        }
      } else {
        console.error("Invalid response from server:", resp);
        // You can handle this case as needed, such as showing an error message to the user.
        toast.error('OTP Invalid', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });      
            setOtp(['','','',''])
            inputRefs[0].current.focus();


      }
    } catch (error) {
      // Handle the error as needed, you can show a user-friendly message or perform other actions.
      // For example:
      // showError("Invalid OTP. Please try again.");
      toast.warn('Full Name And Occupation Must Be Povided', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    } finally{
      setLoading(false)

    }
  };
  
  

  const otpHandler = (e, nextInput) => {

    var letters = /^[A-Za-z]+$/;
    if (e.target.value.match(letters)) {
      e.target.value = ''
      return
    }

    // const selectedInput = document.getElementById(currentInput);
    // console.log(currentValue);

    if (e.target.value.length > 0) {
      if (nextInput !== "submitOTP") {
        document.getElementById(nextInput).select();
      }
    }
  };


  useEffect(() => {
    if (otpCode) {
      document.getElementById("otp-1").value = otpCode[0]
      document.getElementById("otp-2").value = otpCode[1]
      document.getElementById("otp-3").value = otpCode[2]
      document.getElementById("otp-4").value = otpCode[3]

      // after entering code it will submit form
      // Otpuser();
    }

  }, [otpCode])



  const handleChange = async (value, index) => {
    if (!isNaN(value) && value !== '') {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < 3) {
        inputRefs[index + 1].current.focus();
      } else if (index === 3) {
        await handleSubmit(newOtp); // Automatically submit after the last digit
      }
    } else if (value === '') {
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);

      if (index > 0) {
        inputRefs[index - 1].current.focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp[index] === '') {
      if (index > 0) {
        inputRefs[index - 1].current.focus();
      }
    }
  };

  const handleSubmit = (prop) => {
    const enteredOtp = prop.join('');
    setLoading(true);
    Otpuser(enteredOtp);
  };


  // otp

  const handleButtonClick = () => {
    setIsButtonDisabled(true);
    setCountdown(300); // 300 seconds (5 minutes)
  };

  useEffect(() => {
    if (countdown > 0 && isButtonDisabled) {
      const interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else if (countdown === 0 && isButtonDisabled) {
      setIsButtonDisabled(false);
    }
  }, [countdown, isButtonDisabled]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };


  return (
    <>
          <ToastContainer />

    <div className="OtpFormCenter">
      <section className={`otp ${direction == 'rtl' && ('rtl')}`}>
        <div className="otp-content">
          <img draggable={false} src={logo} alt="Logo" className="logo" />
          <span className="border-middle"></span>
          <Title>{t("otp.title")}</Title>
          <Text>
            {t("otp.text")}&nbsp;<strong>{phone}</strong>
          </Text>

          <div className="otp-form">
            {otp.map((digit, index) => (
              <input
                key={index}
                ref={inputRefs[index]}
                type="text"
                maxLength={1}
                value={digit}
                onChange={(e) => handleChange(e.target.value, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, '');
                }}
              />
            ))}
          </div>
          <div className="single-link">
            <Link to={`/login`}>{t("otp.link")}</Link>
          </div>
          {
            loading && <SingleLoading />
          }

          <div className="btn-div">
          </div>
          <div className="btn-div">

            <button className={isButtonDisabled ? "resend-otp-disabled" : "resend-otp-btn"} onClick={handleButtonClick} disabled={isButtonDisabled}>
              {isButtonDisabled ? t("otp.retryAfter") + formatTime(countdown) : `${t("otp.button")}`}
            </button>
          </div>
        </div>
      </section>
    </div>
    </>


  );
};

export default OTP;
