import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { setupListeners } from "@reduxjs/toolkit/query";
import { combineReducers } from "redux";
import { apiSlice } from "../features/api/apiSlice.js";
import authReducer from "../features/auth/authReducer.js";

let reducers = combineReducers({
    [apiSlice.reducerPath]: apiSlice.reducer,
    authData: authReducer
});

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["authData"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(apiSlice.middleware)
});

const persistor = persistStore(store);

setupListeners(store.dispatch);

export { store, persistor };
