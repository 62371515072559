import { apiSlice } from '../api/apiSlice.js';

const lang = localStorage.getItem('i18nextLng');

export const loyaltySlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getLoyaltyProgramItems: builder.query({
            query: () => ({
                url: "/user/loyalty-program/items?lang="+lang,
                method: "GET",
            }),
        }),
        getLoyaltyProgramReceipts: builder.query({
            query: () => ({
                url: "/user/loyalty-program/receipts?lang="+lang,
                method: "GET",
            }),
        }),
        getLoyaltyProgramRedemptionReq: builder.query({
            query: () => ({
                url: "/user/loyalty-program/redemption-requests?lang="+lang,
                method: "GET",
            }),
        }),
        getLoyaltyProgramRedemptionReqById: builder.query({
            query: (request_id) => ({
                url: `/user/loyalty-program/redemption-requests/${request_id}?lang=${lang}`,
                method: "GET",
            }),
        }),
        requestLoyaltyProgramRedemption: builder.mutation({
            query: (body) => ({
                url: "/user/loyalty-program/redemption-requests?lang="+lang,
                method: "POST",
                body,
            }),
        }),
        postLoyaltyProgramRedemption: builder.mutation({
            query: ({id,payload}) => ({
                url: `/user/loyalty-program/redemption-requests/${id}?lang=${lang}`,
                method: "POST",
                body:payload,
            }),
        }),
        addLoyaltyProgramReceipt: builder.mutation({
            query: (body) => ({
                url: `/user/loyalty-program/receipts?lang=${lang}`,
                method: "POST",
                body,
            }),
        }),
    }),
});
export default loyaltySlice.reducer;
export const {useGetLoyaltyProgramItemsQuery,
    useGetLoyaltyProgramReceiptsQuery,
    useGetLoyaltyProgramRedemptionReqQuery,
    useGetLoyaltyProgramRedemptionReqByIdQuery,
    useRequestLoyaltyProgramRedemptionMutation,
    usePostLoyaltyProgramRedemptionMutation,
    useAddLoyaltyProgramReceiptMutation
} = loyaltySlice;
