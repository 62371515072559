import { Navigate, Outlet } from 'react-router-dom';
import useAuth from './useAuth';
import {useSelector} from "react-redux";
import Header from "../components/Header/Header";

export default function PrivateOutlet() {
    const language = localStorage.getItem('i18nextLng');
    const auth = useAuth();
    const {user} = useSelector(state => state.authData) ;

    if(language === 'en-US'){
        // navigate('/');
        return <Navigate to='/' />
        // return;
    }else if(language !== 'en-US' && !user){
        return <Navigate to='/login' />
    }else{
        return <><Header/><Outlet /></>
    }
    
    // const auth = true;
        
    // return auth ? <Outlet /> : <Navigate to="/login" />
  
}
