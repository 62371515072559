import React from "react";
import { useNavigate } from "react-router-dom";
import "./NotFound.css";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <section className="not-found">
      <div className="app-container">
        <h3>Not found</h3>
        <button onClick={() => navigate(-1)}>Go Back</button>
      </div>
    </section>
  );
};

export default NotFound;
