import React from 'react';
import logo from '../../assets/logo.png';
import './LoadingPage.css';


const LoadingPage = () => {
    return (
        <div className='loading-container'>
            <img  draggable={false} src={logo} alt="" />
            <div className="custom-loader"></div>
        </div>
    );
};

export default LoadingPage;


export function SingleLoading () {
    return(
        <div className='custom-loading-container'>
<div className="custom-loader"></div>
</div>

    )
}