import React, { useState } from "react";
import ShowRewardDetails from "../ShowRewardDetails/ShowRewardDetails";
import "./SingleReward.css";
import { useTranslation } from "react-i18next";

const SingleReward = ({ reward, rewardPoints }) => {
  const { imageUrl, name, price } = reward;
  const [showDetails, setShowDetails] = useState(false);
  const { t } = useTranslation();

  // const disabledClass = status === "disabled" ? "disabled" : "";
    const disabledClass = rewardPoints < price ? "disabled" : "";

  return (
    <>
  
      <div className={`single-reward ${disabledClass}`}>
        <div className="reward-img">
          <img  draggable={false} src={imageUrl} alt="" />
        </div>
        <div className="reward-body">
          <h4>{name}</h4>
          <button onClick={() => setShowDetails(true)} disabled={price > rewardPoints} className={price > rewardPoints ? 'disabled' : ''}>{price} {t("points.label")} </button>
        </div>
      </div>

      {showDetails && (
        <ShowRewardDetails
          setShowDetails={setShowDetails}
          reward={reward}
          rewardPoints={rewardPoints}
        ></ShowRewardDetails>
      )}
    </>
  );
};

export default SingleReward;
