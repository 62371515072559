import React from "react";
import { NavLink } from "react-router-dom";

import "./LinksTab.css";
import { useTranslation } from "react-i18next";

const LinkTab = () => {
  const { t } = useTranslation();
  return (
    <div className="links-tab">
      <div className="links">
        <NavLink to={`/home/reward`}>{t("linkTab.reward")}</NavLink>
        <NavLink to={`/home/request`}>{t("linkTab.request")}</NavLink>
      </div>
    </div>
  );
};

export default LinkTab;
