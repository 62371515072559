import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import goBack from "../../assets/go-back.svg";
import { LanguageDirection } from "../../utility/LanguageDirection";
import EmptyHistory from "../EmptyHistory/EmptyHistory";
import SingleHistory from "../SingleHistory/SingleHistory";
import "./History.css";
import {
  useGetLoyaltyProgramReceiptsQuery
} from "../../features/loyalityProgram/loyalitySlice";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Grid } from "@mui/material";

const History = () => {
  const navigate = useNavigate();
  const direction = LanguageDirection();
  const {t} = useTranslation();
  const {data,isLoading} = useGetLoyaltyProgramReceiptsQuery();

  return (
    <section className={`points-history ${direction == 'rtl' && ('rtl')}`}>
      <div className="history-header">
        <h2
        style={{
          fontWeight:"800",
          textAlign:"center",
          display:"flex",
          alignItems:"center",
          justifyContent:"center"
        }}
        >{t("history.title")}</h2>
        <button onClick={() => navigate(-1)} className="go-back">
        {direction === "ltr" ? (
            <ArrowBackIosNewIcon sx={{color:"#25485e"}}/>
          ):
          <ArrowForwardIosIcon sx={{color:"#25485e"}}/>
          }
                 </button>
      </div>

      <div className="history-body">
        <div className="scroller-container">
          {data?.receipts?.length > 0 && (
            <>
              <div className="history-list">
                <Grid container justifyContent="space-between"
                 sx={{
                  paddingLeft:{lg:"24px",md:"24px",sm:"12px",xs:"12px"},
                  paddingRight:{lg:"24px",md:"24px",sm:"12px",xs:"12px"},
                  marginTop:"24px"
                }}
                >
                {data?.receipts?.map((history) => (
                  <Grid lg={5.89} md={5.89} xs={12}
                  sx={{
                    border:"1px solid #2a5675",
                    marginBottom:"20px",
                    zIndex:"9999",
                    borderRadius:"8px"
                  }}
                  >
<SingleHistory
                    key={history.id}
                    history={history}
                  ></SingleHistory>
                  </Grid>
                  
                ))}
                </Grid>
               
              </div>
            </>
          )}
          {/* skeleton div */}
          {isLoading &&
              (["1", "2", "3", "4"].map((item, index) =>
                      <div key={index} className="single-reward-skeleton">
                        <div className="skeleton skeleton-img"></div>
                        <div className="reword-texts">
                          <div className="skeleton text"></div>
                          <div className="skeleton btns"></div>
                        </div>
                      </div>
                  )
              )
          }

          {data?.receipts?.length === 0 && <EmptyHistory></EmptyHistory>}
        </div>
      </div>
    </section>
  );
};

export default History;
