import React from "react";
import "./SingleHistory.css";
import receiptImage from "../../assets/receipt.png";
import { useTranslation } from "react-i18next";
import { LanguageDirection } from "../../utility/LanguageDirection";
import Tooltip from '@mui/material/Tooltip';



const SingleHistory = ({ history }) => {

  const { status, totalPoints, updateComment } = history;
  const { t } = useTranslation();
  const direction = LanguageDirection();

  let statusElement;
  if (status === "PENDING") {
    statusElement = (
      <Tooltip title={updateComment || ""} enterTouchDelay={0}>
        <span className="pending">{t(`singleHistory.status.${status}`)}</span>
      </Tooltip>
    );
  } else if (status === "REJECTED") {
    statusElement = (
      <Tooltip title={updateComment || ""} enterTouchDelay={0}>
        <span className="rejected">{t(`singleHistory.status.${status}`)}</span>
      </Tooltip>
    );
  } else {
    statusElement = <Tooltip title={updateComment || ""} enterTouchDelay={0}><span className="complete">+{totalPoints} {t("points.label")}</span></Tooltip>;
  }
  return (
    <div className="single-history">
      <div className="history-info">
        <div className="content">
          <img  draggable={false} src={receiptImage} alt="History img" />
          <h4>{t("singleHistory.receipt")}</h4>

        </div>
        <div className="status" dir={direction}>{statusElement}</div>
      </div>
    </div>
  );
};

export default SingleHistory;
