import React, { useState } from "react";
import AvailablePoints from "../AvailablePoints/AvailablePoints";
import LinkTab from "../LinksTab/LinksTab";
import img from "../../assets/drill.png";
import SingleRequest from "../SingleRequest/SingleRequest";
import { LanguageDirection } from "../../utility/LanguageDirection";
import './Requests.css'
import { useDispatch, useSelector } from "react-redux";
import {
  useGetLoyaltyProgramReceiptsQuery,
  useGetLoyaltyProgramRedemptionReqQuery
} from "../../features/loyalityProgram/loyalitySlice";
import { Grid } from "@mui/material";


const requestList = [
  {
    id: 1,
    img,
    title: "STANLEY Power Tool Cordless",
    points: 150,
    status: "pending",
  },
  {
    id: 2,
    img,
    title: "STANLEY Power Tool Cordless",
    points: 150,
    status: "processing",
  },
  {
    id: 3,
    img,
    title: "STANLEY Power Tool Cordless",
    points: 150,
    status: "delivered",
  },
  {
    id: 4,
    img,
    title: "STANLEY Power Tool Cordless",
    points: 150,
    status: "delivered",
  },
  {
    id: 5,
    img,
    title: "STANLEY Power Tool Cordless",
    points: 150,
    status: "delivered",
  },
  {
    id: 6,
    img,
    title: "STANLEY Power Tool Cordless",
    points: 150,
    status: "pending",
  },
  {
    id: 7,
    img,
    title: "STANLEY Power Tool Cordless",
    points: 150,
    status: "processing",
  },
  {
    id: 8,
    img,
    title: "STANLEY Power Tool Cordless",
    points: 150,
    status: "delivered",
  },
];

const Requests = () => {
  const direction = LanguageDirection();
  const [rewardPoints, setRewordPoints] = useState(1040);
  const { data, isLoading } = useGetLoyaltyProgramRedemptionReqQuery();
  const { user } = useSelector(state => state.authData);



  return (
    <section className={`requests ${direction == 'rtl' && ('rtl')}`}>
      <AvailablePoints rewardPoints={user?.rewardPoints}></AvailablePoints>
      <LinkTab></LinkTab>
      <div className="request-list inner-shadow">
        <div className="scroller-container" >
          <Grid container  justifyContent="space-between"
          sx={{
            paddingLeft:{lg:"24px",md:"24px",sm:"12px",xs:"12px"},
            paddingRight:{lg:"24px",md:"24px",sm:"12px",xs:"12px"},
            marginTop:"24px",
          }}
          >

          {data?.requests?.map((request) => (
            <Grid lg={5.89} md={5.89} xs={12}
            sx={{
              border:"1px solid #2a5675",
              marginBottom:"20px",
              borderRadius:"8px"
            }}
            >
              <SingleRequest key={request.id} request={request}></SingleRequest>
              </Grid>
          ))}
          </Grid>

          {/* skeleton div */}
          {isLoading &&
            (["1", "2", "3", "4"].map((item, index) =>
              <div key={index} className="single-request-skeleton">
                <div className="skeleton skeleton-img"></div>
                <div className="request-texts">
                  <div className="skeleton text"></div>
                  <div className="skeleton text2"></div>
                </div>
                <div className="skeleton btns"></div>
              </div>
            ))}
        </div>
      </div>

    </section>
  );
};

export default Requests;
