import i18n from "i18next";
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

const availableLanguages = ['en', 'ar'];

i18n
    ?.use(i18nBackend)
    ?.use(LanguageDetector)
    ?.use(initReactI18next)
    ?.init({
        fallbackLng: "en", //set default language here
        interpolation: {
            escapeValue: false,
        },
        detection: {
            checkWhitelist: true, // options for language detection
        },
        whitelist: availableLanguages,
        backend: {
            loadPath: `https://promotions.hbmc.sa/i18n/{{lng}}.json`,
            // loadPath: `http://localhost:3000/i18n/{{lng}}.json`,

        },
    });

export default i18n;
