import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Webcam from 'react-webcam';
import goBack from "../../assets/go-back.svg";
import { LanguageDirection } from "../../utility/LanguageDirection";
import "./UploadReciept.css";
import { useAddLoyaltyProgramReceiptMutation } from "../../features/loyalityProgram/loyalitySlice";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SingleLoading } from "../LoadingPage/LoadingPage";
import cameraNotFound from "../../assets/cameraNotFound.png";

const UploadReciept = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const direction = LanguageDirection();
  const [uploadReceipt] = useAddLoyaltyProgramReceiptMutation();
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [cameraAvailable, setCameraAvailable] = useState(false); // Start with false and update based on permission

  const captureImageHandler = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef]);

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then(() => setCameraAvailable(true))
      .catch((error) => {
        console.error('Error accessing camera:', error);
        setCameraAvailable(false);
      });
  }, []);

  const retakeImageHandler = () => {
    setImgSrc(null);
  };

  const useImageHandler = async () => {
    setLoading(true);
    const resp = await uploadReceipt();
    const receiptUrl = resp.data.url;

    if (imgSrc) {
      // Convert the base64/URL data to a blob to prepare for uploading
      try {
        const fetchResponse = await fetch(imgSrc);
        const blob = await fetchResponse.blob();

        // PUT request to upload the blob to S3
        const options = { method: 'PUT', body: blob, headers: { 'Content-Type': 'image/jpeg' } };
        await fetch(receiptUrl, options);

        toast.success(t("uploadReceipt.uploadSuccess"));

        // Redirect to the home page after a short delay
        setTimeout(() => {
          navigate('/home/reward');
        }, 2000);
      } catch (err) {
        console.error('Error uploading image:', err);
        toast.error(t("uploadReceipt.uploadFailure"));
      } finally {
        setLoading(false);
      }
    }
  };


  return (
    <>
      <ToastContainer />
      <section className={`upload-reciept-component ${direction === 'rtl' ? 'rtl' : ''}`}>
        <div className="up-reciept-header" style={{ direction: direction === 'ltr' ? "rtl" : "ltr" }}>
          <h2 style={{ fontWeight: "800", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}>
            {t("uploadReceipt.title")}
          </h2>
          <button
            style={{ display: "flex", alignItems: "center", justifyContent: "center", direction: direction === "rtl" ? "rtl" : "ltr" }}
            onClick={() => navigate(-1)}
            className="go-back"
          >
            {direction === "ltr" ? <ArrowBackIosNewIcon sx={{ color: "#25485e" }} /> : <ArrowForwardIosIcon sx={{ color: "#25485e" }} />}
          </button>
        </div>

        <div className="uploaded-image">
          {!cameraAvailable ? (
            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "24px", alignItems: "center", marginBottom: "12px" }}>
              <img style={{ backgroundBlendMode: "color-burn", width: "100%", maxWidth: "300px", height: "100%", maxHeight: "400px", borderRadius: "16px" }}
                src={cameraNotFound} alt="Camera not found" />
              <p style={{ fontSize: "18px", color: "#224e6b", fontWeight: "800" }}>
                {t("uploadReceipt.NoCameraFound")}
              </p>
            </div>
          ) : (
            imgSrc ? (
              <img draggable={false} src={imgSrc} alt="Uploaded receipt" />
            ) : (
              <Webcam
                className="webcam"
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                screenshotQuality={0.8}
                videoConstraints={{
                  width: 1280,
                  height: 720,
                  facingMode: 'environment'
                }}
              />
            )
          )}
        </div>
        {imgSrc ? (
          <div className="upload-bottons">
            {!loading ? (
              <button onClick={useImageHandler}>{t("uploadReceipt.buttonUse")}</button>
            ) : (
              <SingleLoading />
            )}
            <button onClick={retakeImageHandler}>{t("uploadReceipt.buttonRetake")}</button>
          </div>
        ) : (
          cameraAvailable && (
            <div className="pic-button">
              <button onClick={captureImageHandler} style={{ width: "100%", fontSize: "18px", fontWeight: "600" }}>
                {t("uploadReceipt.capturePhoto")}
              </button>
            </div>
          )
        )}
      </section>
    </>
  );
};

export default UploadReciept;
