import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import emptyHistoryImg from "../../assets/empty-image.png";
import { LanguageDirection } from "../../utility/LanguageDirection";
import "./EmptyHistory.css";

const EmptyHistory = () => {

  const navigate = useNavigate();
  const direction = LanguageDirection();
  const { t } = useTranslation();

  return (
    <div className={`empty-history ${direction == 'rtl' && ('rtl')}`}>
      <img  draggable={false} src={emptyHistoryImg} alt="No history available" />
      <h3>{t("emptyHistory.title")}</h3>
      <p>{t("emptyHistory.text")}</p>
      <button
        className="upload-receipt"
        onClick={() => navigate(`/home/upload-reciept`)}
      >
        {t("emptyHistory.button")}
      </button>
    </div>
  );
};

export default EmptyHistory;
