import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/Header/Header";
import { useLanguage } from "../../Contex/LanguageProvider";
import "./Home.css";
import {useDispatch, useSelector} from "react-redux";
import {SetUser} from "../../features/auth/authReducer";

const Home = () => {
  const { language } = useLanguage();
  return (
    <main className={`main-container ${language === "ar" ? "rtl-arabic" : ""}`}>
      <div className="app-home-content">
        <Header></Header>
        <Outlet></Outlet>
      </div>
    </main>
  );
};

export default Home;
