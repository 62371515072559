import { createBrowserRouter } from "react-router-dom";
import History from "../components/History/History";
import Login from "../components/Login/Login";
import OTP from "../components/OTP/OTP";
import UserAuth from "../components/NameOccupation/UserAuth";
import Requests from "../components/Requests/Requests";
import Rewards from "../components/Rewards/Rewards";
import SelectLanguage from "../components/SelectLanguage/SelectLanguage";
import UploadReciept from "../components/UploadReciept/UploadReceipt";

import Home from "../layout/Home/Home";
import Main from "../layout/Main/Main";
import NotFound from "../layout/NotFound/NotFound";
import LoadingPage from "../components/LoadingPage/LoadingPage";
import PrivateOutlet from "../auth/PrivateOutlet";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main></Main>,
    children: [
      { path: "/", element: <SelectLanguage></SelectLanguage> },
      { path: "/login", element: <Login></Login> },
      { path: "/otp", element: <OTP></OTP> },
      { path: "/auth", element: <UserAuth></UserAuth> },
      { path: "/loader", element: <LoadingPage></LoadingPage> },
    ],
  },
  {
    path: "/home",
    element:<PrivateOutlet><Home></Home></PrivateOutlet>,
    children: [
      { path: "", element: <Rewards></Rewards> },
      { path: "reward", element: <Rewards></Rewards> },
      { path: "request", element: <Requests></Requests> },
      { path: "history", element: <History></History> },
      { path: "upload-reciept", element: <UploadReciept></UploadReciept> },
    ],
  },
  {
    path: "*",
    element: <NotFound></NotFound>,
  },
]);

export default router;
