import React, { useEffect, useState } from "react";
import blueStart from "../../assets/star-circle-blue.png";
import { LanguageDirection } from "../../utility/LanguageDirection";
import Text from "../Text/Text";
import Title from "../Title/Title";
import "./ShowRewardDetails.css";
import {useRequestLoyaltyProgramRedemptionMutation} from "../../features/loyalityProgram/loyalitySlice";
import { useDispatch } from 'react-redux';
import { updateUserPoints } from '../../features/auth/authReducer'
import { useSelector } from "react-redux";
import {useTranslation} from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SingleLoading } from "../LoadingPage/LoadingPage";
import { useGetMeQuery } from "../../features/auth/authSlice";
import StarBorderIcon from '@mui/icons-material/StarBorder';

const ShowRewardDetails = ({ setShowDetails, reward, rewardPoints }) => {
  const direction = LanguageDirection();
  const { data, refetch } = useGetMeQuery();

  // const { fullTitle, largeImage, catagory, description, points } = reward;
  const { name, imageUrl, category, description, price,id } = reward;
  const [multiple, setMultiple] = useState(1);
  const [redeemPoints, setRedeemPoints] = useState(0);
  const [isAvailablePoints, setIsAvailablePoints] = useState(true);
  const [leftRewordPoints, setLeftRewordPoints] = useState(rewardPoints);
  const [requestRedemption] = useRequestLoyaltyProgramRedemptionMutation();
  const [loading,setLoading]=useState(false)

  const {t} = useTranslation();
  

  const dispatch = useDispatch();

  const handleUpdatePoints = (newPoints) => {
      dispatch(updateUserPoints(newPoints));
  };


  const redeemPointsSubmit = async () => {
    setLoading(true);
  
    try {
      const resp = await requestRedemption({ rewardId: id, quantity: multiple });
  
      if (resp.hasOwnProperty('error')) {
        // Handle error if the API returns an error
        toast.error('Error occurred', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }
  
      // If the API call is successful, show success toast
      toast.success('Redemption successful', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  
      // setShowDetails(false);
      hideModal()
      handleUpdatePoints(data?.rewardPoints - redeemPoints);
      const fetchData = async () => {
        try {
          await refetch();
        } catch (error) {
          console.error('Error refetching data:', error);
        }
      };
      fetchData()
    } catch (error) {
      // Handle any other errors that may occur during the API call
      console.error(error);
      toast.error('An error occurred', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setLoading(false);
    }
  };
  

  const hideModal = () => {
    // Create a style element
    const styleElement = document.createElement('style');
  
    styleElement.textContent = `
      ::-webkit-scrollbar {
        width: 0;
      }
    `;
  
    document.head.appendChild(styleElement);
  
    const hiddenScrollElement = document.getElementById("hiddenScroll");
    hiddenScrollElement.classList.add("reward-container2", "hide-scrollbar");
  
    const timeoutId = setTimeout(() => {
      setShowDetails(false);
      hiddenScrollElement.classList.remove("reward-container2", "hide-scrollbar");
  
      // Remove the dynamically added style element when the timeout is complete
      document.head.removeChild(styleElement);
    }, 1500);
  
    return () => clearTimeout(timeoutId);
  };
  

    

  useEffect(() =>{
    const totalRedeemPoints = price * multiple ;
    const availableRewordPoints = rewardPoints - totalRedeemPoints;

    if(availableRewordPoints > price){
        setRedeemPoints(totalRedeemPoints);
        setLeftRewordPoints(availableRewordPoints);
        setIsAvailablePoints(true);
    }else if(availableRewordPoints < price){
        setIsAvailablePoints(false);
        return;
    }
  }, [multiple, price, rewardPoints])

  useEffect(() => {
  
    const hiddenScrollElement = document.getElementById("hiddenScroll");
    hiddenScrollElement.classList.add("hide-overflow");
    const timeoutId = setTimeout(() => {
      hiddenScrollElement.classList.remove("hide-overflow");

    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []); 

  


  return (
    <>
          <ToastContainer />
          <div
    id="hiddenScroll" 
      className={`show-reward-details-component  ${direction === 'rtl' && ('rtl')}`}
      onClick={() => {
        setShowDetails(false);
      }}
    >

      <div style={{overflowY:"hidden !important"}}  className="reward-container" onClick={(e) => e.stopPropagation()}>
        {/* <button
          className="back-button"
          onClick={() => setShowDetails(false)}
        ></button> */}
        <div
        id="hideModal"
        // onClick={()=>setShowDetails(false)}
        onClick={hideModal}
        style={{
          display:"flex",
          justifyContent:"end",
          cursor:"pointer"
        }}
        >
        <CloseIcon sx={{position:"fixed"}}/>

        </div>

        <div className="detailsGroup" style={{textAlign: 'center'}}>
          <img  draggable={false} src={imageUrl} alt="" /></div>
         <p className="categoryTest">{category}</p>
        <Title>{name}</Title>
        <Text>{description}</Text>
        <div className="redeem-points">
          <div className="d-flex">
            <div className="redeem-output">
              <Title>
                  {/* <img  draggable={false} src={blueStart} alt="" /> */}
                  <StarBorderIcon/>
                <span>{multiple}</span>
                <span>×</span>
                <span>{price}</span>
              </Title>
              <Text>{t("showRewardDetails.remaining")} {rewardPoints - (price * multiple)}</Text>
            </div>
            <div className="redeem-multiple">
              <button
                onClick={() => setMultiple((prev) => prev - 1)}
                className={`${multiple <= 1 && "disabled"}`}
              >
                -
              </button>
              <input
                type="number"
                value={multiple}
                onChange={(e) => setMultiple(e.target.value)}
              />
              <button className={`${!isAvailablePoints && "disabled"}`} onClick={() => setMultiple((prev) => prev + 1)}>
                +
                </button>
            </div>
          </div>
        </div>
        <div className="redeem-button">
          {loading ? (
            <SingleLoading/>
          ):(
<button onClick={redeemPointsSubmit}>
            {t("showRewardDetails.redeem")}
          </button>
          )}
          
        </div>
      </div>
    </div>
    </>
    
  );
};

export default ShowRewardDetails;
