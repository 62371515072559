import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import "./AvailablePoints.css";
import startCircle from "./images/star-circle.png";
import { useDispatch, useSelector } from "react-redux";
import { useGetMeQuery } from "../../features/auth/authSlice";
import StarBorderIcon from '@mui/icons-material/StarBorder';
const AvailablePoints = ({ rewardPoints }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data, refetch } = useGetMeQuery();
// console.log("sds",data?.rewardPoints)

  return (
    <section className="available-points">
      <div className="points-container">
        <div className="points-body">
          <h2 style={{fontWeight:"800"}}>{t("availablePoints.title")}</h2>
          <div className="points-view">
            <StarBorderIcon/>
            {!data?.rewardPoints ? (
              <strong className="skeleton rewords-skeleton"></strong>
            ) : (
              <strong>{data?.rewardPoints}</strong>
            )}
          </div>
          <p>{t("availablePoints.text")}</p>
        </div>
        <div className="points-bottom">
          <button onClick={() => navigate(`/home/history`)}>
            {t("availablePoints.buttonHistory")}
          </button>
          <button onClick={() => navigate(`/home/upload-reciept`)}>
            {t("availablePoints.buttonReceipt")}
          </button>
        </div>
      </div>
    </section>
  );
};

export default AvailablePoints;
