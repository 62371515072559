import React, { useEffect, useState } from "react";
import starPoint from "../../assets/gray-star.png";
import ShowConfirmRequest from "../ShowConfirmRequest/ShowConfirmRequest";
import './SingleRequest.css';
import {useTranslation} from "react-i18next";

const SingleRequest = ({ request }) => {
    const {t} = useTranslation();

    const { product, cost,quantity, status,id } = request;
    const [showRequestConfirmation, setShowRequestConfirmation] = useState(false);
    const [currentStatus, setCurrentStatus] = useState(status);

    useEffect(() => {
      let s;
      if (status === "PENDING") {
          s = <span className="pending">{t(`requests.status.${status}`)}</span>;
          setCurrentStatus(s)
      } else if (status === "DELIVERED") {
          s =  <span className="delivered">{t(`requests.status.${status}`)}</span>;
          setCurrentStatus(s)
      } else {
          s = <button style={{borderRadius:"8px"}} onClick={()=>setShowRequestConfirmation(true)} className="processing">{t("requests.status.PENDING_CONFIRMATION")}</button>;
          setCurrentStatus(s)
      }

        setCurrentStatus(s)
    }
    ,[status, t])




    const handleMakeDelivered = () =>{
      setCurrentStatus(<span className="delivered">{t(`requests.status.DELIVERED`)}</span>);
    }

//   console.log(request);

    return (
        <div className="single-request">
            <div className="content"
            style={{
                zIndex:"1"
            }}
            >
                <div className="img">
                    <img  draggable={false} src={product?.imageUrl} alt="" />
                </div>
                <div className="info">
                    <h4>{product?.name}</h4>
                    <p className="point">
                        <img  draggable={false} src={starPoint} alt="" />
                        <span>{cost} {t("points.label")}</span>
                    </p>
                </div>
            </div>
            <div className="status">{currentStatus}</div>
            {showRequestConfirmation && (
                <ShowConfirmRequest
                    setShowDetails={setShowRequestConfirmation}
                    handleMakeDelivered={handleMakeDelivered}
                    id={id}
                    //   rewordPoints={rewordPoints}
                ></ShowConfirmRequest>
            )}
        </div>
    );
};

export default SingleRequest;
