import React from "react";
import { RouterProvider } from "react-router-dom";
import router from "./router/router";
import "./App.css";
// inport RTL Styles
import "./arrtl.css";
import "./RTL.css";
import LanguageProvider from "./Contex/LanguageProvider";
import { Suspense } from "react";
import LoadingPage from "./components/LoadingPage/LoadingPage";

const App = () => {
  return (
    // <Suspense fallback={"Loading"}>
    <Suspense fallback={<LoadingPage />}>
      <LanguageProvider>
        <RouterProvider router={router}></RouterProvider>
      </LanguageProvider>
    </Suspense>
  );
};

export default App;
