import { apiSlice } from '../api/apiSlice.js';
export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        sendOTP: builder.mutation({
            query: (phone) => ({
                url: "/user/auth/send-sms",
                method: "POST",
                body: phone,
            }),
        }),
        verifyOTP: builder.mutation({
            query: (data) => ({
                url: "/user/auth/verify-otp",
                method: "POST",
                body: data,
            }),
        }),
        getMe: builder.query({
            query: () => ({
                url: "/user/me",
                method: "GET",
            }),
        }),
        updateMe: builder.mutation({
            query: (data) => ({
                url: "/user/me",
                method: "PATCH",
                body: data,
            }),
        }),
    }),
});
export default authApiSlice.reducer;
export const { useSendOTPMutation,useVerifyOTPMutation,useGetMeQuery,useUpdateMeMutation } = authApiSlice;
