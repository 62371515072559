import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import { LanguageDirection } from "../../utility/LanguageDirection";

import MenuIcon from '@mui/icons-material/MoreVert'; // Or any other icon of your choice
//import lock icon
import LockIcon from '@mui/icons-material/Lock';
import { Menu, MenuItem, Dialog, ListItemIcon, ListItemText, DialogTitle, List, Grid, Box, Typography } from '@mui/material';
import "./Header.css";
import { useDispatch } from "react-redux";
import {LANGUAGES} from '../../constants/index'
import { logout } from "../../features/auth/authReducer";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useGetMeQuery } from "../../features/auth/authSlice";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [languageDialogOpen, setLanguageDialogOpen] = useState(false);
  const data=useGetMeQuery()
  const dispatch = useDispatch();


  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
};


const handleMenuClose = () => {
  setAnchorEl(null);
};

const handleLanguageClick = () => {
  setLanguageDialogOpen(true);
  handleMenuClose();
};

const handleLogout = () => {
  dispatch(logout()); // Dispatch logout action
  handleMenuClose();
  window.location.reload();
};



  const { i18n, t } = useTranslation();

  const onChangeLang = (code) => {
    i18n?.changeLanguage(code);
    setLanguageDialogOpen(false);
    // refresh page
    window.location.reload();
  };

  const direction = LanguageDirection();
  var currentLanguage;

  for(let i=0; i<LANGUAGES.length; i++){
    if (i18n.language === LANGUAGES[i].code){
      currentLanguage = LANGUAGES[i]
    }
  }


  return (

    <header className={`header-area`} style={{ flexDirection: direction == 'rtl' ? 'row-reverse' : 'row' }}>
      <div className="logo-area">
        <Link to={`/home/reward`}>
          <img  draggable={false} src={logo} alt="Logo" />
        </Link>
      </div>

      <Grid
      sx={{
        display:"flex",
        alignItems:"center",
        gap:"12px",
        direction:direction === "rtl" ? "rtl" : "ltr"
      }}
      >
      <Grid>
              <Box
              sx={{
                display:"flex",
                alignItems:"center",
                gap:"8px"
              }}
              >
                 <Typography
                 sx={{
                  fontWeight:"200",
                  fontSize:"16px",
                  fontFamily:"Poppins, sans-serif",
                 }}
                 >
                  {data?.data?.name}
                </Typography>
                <AccountCircleIcon/>

              </Box>
             </Grid>
            <MenuIcon onClick={handleMenuClick} sx={{cursor:"pointer"}}/>

            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleLanguageClick} dir={direction} className={direction}>
                    <ListItemIcon>
                      <img draggable={false} src={currentLanguage.logo} width={32} alt={currentLanguage.code.toUpperCase()} />
                    </ListItemIcon>
                    <ListItemText primary={currentLanguage.label} />
                </MenuItem>
                <MenuItem onClick={handleLogout} dir={direction} className={direction}>
                  <ListItemIcon>
                    <LockIcon style={{height: 20}}/>
                  </ListItemIcon>
                    <ListItemText primary={t("header.logout")} />
                </MenuItem>
            </Menu>

            <Dialog    open={languageDialogOpen} onClose={() => setLanguageDialogOpen(false)} dir={direction}>
          <DialogTitle className={direction}>{t("header.chooseLanguage")}</DialogTitle>
          <List>
            {LANGUAGES.map((lang) => (
              <MenuItem key={lang.code} onClick={() => onChangeLang(lang.code)}>
                <ListItemIcon>
                  <img draggable={false} src={lang.logo} width={32} alt={lang.code.toUpperCase()} />
                </ListItemIcon>
                <ListItemText primary={lang.label} />
              </MenuItem>
            ))}
          </List>
        </Dialog>

        </Grid>

    </header>
  );
};

export default Header;
