import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LANGUAGES } from "../../constants";
import { LanguageDirection } from "../../utility/LanguageDirection";
import Text from "../Text/Text";
import Title from "../Title/Title";
import googleTranslateImage from "./images/google-translate.png";
import "./SelectLanguage.css";
import {useSelector} from "react-redux";



// const CustomOption = ({ children, ...props }) => {
//   // You can customize the appearance and behavior of the custom option here

//   return (
//     <components.Option {...props}>
//       {/* <div className="list"> */}
//         <Link to={`/login`}>
//           <img  draggable={false} src={props.data.name == "arabic" ? arabic : english} alt="" />
//           <span className={props.data.name}>{props.data.label}</span>
//         </Link>
//       {/* </div> */}
//     </components.Option >
//   );
// };


const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'transplant',
    borderColor: state.isFocused ? 'none' : 'none', // Customize the border color when focused or not focused
    boxShadow: state.isFocused ? 'none' : 'none', // Customize the box shadow when focused or not focused
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'none' : 'none', // Customize the background color when selected or not selected
    color: state.isSelected ? 'none' : 'none', // Customize the text color when selected or not selected
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'none' : 'none', // Customize the text color of the selected value
  }),
};

const SelectLanguage = () => {

  const { i18n, t } = useTranslation();
  const direction = LanguageDirection();
  const navigate = useNavigate();
  const {user} = useSelector(state => state.authData);

  const onChangeLang = (code) => {
    i18n?.changeLanguage(code);
  };

  const [selectedOption, setSelectedOption] = useState(null);
  // const [options, setOptions] = useState(LANGUAGES);

  const handleSelectChange = (selectedOption) => {
      navigate('/login')
    setSelectedOption(selectedOption);
    onChangeLang(selectedOption.code)
  };


  useEffect(() => {
    if(user){
        navigate('/home/reward')
    }
  }, [user]);


  return (
    <section className={`select-language ${direction == 'rtl' && ('rtl')}`}>
      <div className="select-language-content">
        <img  draggable={false} src={googleTranslateImage} alt="" className="google-translate" />
        <Title className="text-20">{t("selectLanguage.title")}</Title>
        <Text>{t("selectLanguage.text")}</Text>

        <div className="select-languages">
          {/* <ul>
            {LANGUAGES.map(({ code, label, name }) => (
              <li>
                <Link to={`/login`} onClick={() => onChangeLang(code)}>
                  <img  draggable={false} src={name == "arabic" ? arabic : english} alt="" />
                  <span className={name}>{label}</span>
                </Link>
              </li>
            ))}
          </ul> */}
            {LANGUAGES.map((item, index) =>
                <div dir={direction == 'rtl' && 'rtl'} onClick={() => handleSelectChange(item)} key={index} >
                    <img className="select-lang-img" draggable={false} src={item.logo} alt="" />
                    <span className={item.name}>{item.label}</span>
                </div>
            )}

{/*
          <Select
            value={selectedOption}
            onChange={handleSelectChange}
            options={LANGUAGES}
            components={{ Option: CustomOption }}
            isSearchable
            placeholder={t("selectLanguage.placeholder")}
            styles={customStyles}
          /> */}

        </div>
      </div>
    </section>
  );
};

export default SelectLanguage;
