import React, { useState } from "react";
import AvailablePoints from "../AvailablePoints/AvailablePoints";
import LinkTab from "../LinksTab/LinksTab";
import SingleReward from "../SingleReward/SingleReward";
import img from "../../assets/drill.png";
import largeImage from "../../assets/big-image.png";
import "./Rewards.css";
import { LanguageDirection } from "../../utility/LanguageDirection";
import { useSelector} from "react-redux";
import { useGetLoyaltyProgramItemsQuery } from "../../features/loyalityProgram/loyalitySlice";
import { useGetMeQuery } from "../../features/auth/authSlice";
import { useTranslation } from "react-i18next";

const Rewards = () => {
  const [rewardPoints, setRewordPoints] = useState(1040);
  const [searchQuery, setSearchQuery] = useState("");
  const direction = LanguageDirection();
  const { user } = useSelector((state) => state.authData);
  const { data, isLoading } = useGetLoyaltyProgramItemsQuery();
  const dataRefreshed = useGetMeQuery();
  const {t}=useTranslation()


  function normalizeArabic(text) {
    return text
      .replace(/[إأآ]/g, 'ا')  // Replace إ, أ, and آ with ا
      .toLowerCase();  // Convert to lowercase for case-insensitive search
  }
  const normalizedSearchQuery = normalizeArabic(searchQuery);

const filteredRewards = data?.rewards?.filter((reward) => {
  const normalizedRewardName = normalizeArabic(reward.name);
  return normalizedRewardName.includes(normalizedSearchQuery);
});

  return (
    <section className={`rewards ${direction === 'rtl' && ('rtl')}`}>

      <AvailablePoints rewardPoints={dataRefreshed?.data?.rewardPoints} />
      <LinkTab />
      <div className="reward-list inner-shadow">
        <div className="scroller-container">
        <div
        style={{
          paddingTop:"24px",
          paddingBottom:"24px",
          paddingRight:"8px",
          paddingLeft: direction === "rtl" ? "8px" : "0", // Adjust padding for RTL layout
        }}
        >
        <input
        className="rewardSearch"
          type="text"
          style={{ width: "100%", textAlign: direction === "rtl" ? "right" : "left" }} // Ensure full width and text alignment
          placeholder={t("linkTab.SearchRewards")}
          value={searchQuery}
          onChange={(e) => setSearchQuery(normalizeArabic(e.target.value))}
        />
      </div>
          {filteredRewards?.map((reward) => (
            <SingleReward
              key={reward.id}
              reward={reward}
              rewardPoints={dataRefreshed?.data?.rewardPoints}
            />
          ))}

          {/* skeleton div */}
          {isLoading &&
            ["1", "2", "3", "4"].map((item, index) => (
              <div key={index} className="single-reward-skeleton">
                <div className="skeleton skeleton-img"></div>
                <div className="reword-texts">
                  <div className="skeleton text"></div>
                  <div className="skeleton btns"></div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Rewards;
