
// import jwtDecode from "jwt-decode";
// import { useNavigate } from "react-router-dom";


export default function useAuth(){
    // const navigate = useNavigate();
    
    
    const language = localStorage.getItem('i18nextLng');
    

    const token = JSON.parse(localStorage.getItem("token")) || null;
    // if(!token) {
    //     return false;
    // }

    // const decode = jwtDecode(token?.access);
    // if (Date.now() >= decode.exp * 1000) {
    //     navigate("/login");
    // }else return true;
    return token;
}