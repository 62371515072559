import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toast } from 'react-toastify';
import { SetUser } from '../auth/authReducer.js';

const BASE_URL = 'https://api.hbmc.sa';

const baseQueryConfig = fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers,api) => {
        const token = api.getState().authData?.user?.token;
        if (token) {
          headers.set('authorization', token);
        }
        return headers;
    },
});

const interceptBaseQuery = async (args, api, extraOptions) => {
    const result = await baseQueryConfig(args, api, extraOptions);
    if (result?.error?.originalStatus === 401) {
        toast.error('Session Expired');
        api.dispatch(SetUser(null));
    } else if (result?.error) {
        toast.error(result.error.data);
    }
    return result;
};

export const apiSlice = createApi({
    reducerPath: 'apiSlice',
    baseQuery: interceptBaseQuery,
    endpoints: () => ({}),
});
