import React, { useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { useLanguage } from "../../Contex/LanguageProvider";
import "./Main.css";

const Main = () => {
  const { language } = useLanguage();
  const navigate = useNavigate();

  useEffect(() => {
    const pathname = window.location.pathname;
    // console.log(pathname);
    if (pathname === "/") {
      navigate(`/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className={`main-container ${language === "ar" ? "rtl-arabic" : ""}`}>
      <div className="app-content">
        <Outlet></Outlet>
      </div>
    </main>
  );
};

export default Main;
