import React, { createContext, useContext, useState } from "react";

const LanguageContex = createContext();
export const useLanguage = () => useContext(LanguageContex);

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("en");

  const value = { language, setLanguage };

  return (
    <LanguageContex.Provider value={value}>{children}</LanguageContex.Provider>
  );
};

export default LanguageProvider;
