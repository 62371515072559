import axios from "axios";
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import { LanguageDirection } from '../../utility/LanguageDirection';
import "./User-auth.css";
import {useUpdateMeMutation} from "../../features/auth/authSlice";
import {useDispatch} from "react-redux";
import {SetUser} from "../../features/auth/authReducer";
import {useNavigate} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SingleLoading } from "../LoadingPage/LoadingPage";
import { Grid, TextField } from "@mui/material";

const UserAuth = () => {

    const [access, setAccess] = useState(true);
    const [fullName, setFullName] = useState('');
    const [occupation, setOccupation] = useState('');
    const [loading,setLoading]=useState(false)
    const [updateMe] = useUpdateMeMutation();
  const direction = LanguageDirection();
  const { t } = useTranslation();
  const navigate = useNavigate();


  const updateUser = async () =>{
    if(fullName === "" && occupation === ""){
      toast.warn('Full Name And Occupation Must Be Povided', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
    else if (fullName === ""){
      toast.warn('Full Name Must Be Povided', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
    else if (occupation === ""){
      toast.warn('Occupation Must Be Povided', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }

        if(!fullName || !occupation){
            setAccess(false);
            return;
        }
        setLoading(true)
        const resp = await updateMe({name: fullName, metadata: {occupation}});
        if(resp.data){
            navigate('/home/reward');
        }
  }

  // const navigate = useNavigate();
  return (

    // <>
    //   <div className='form-div'>
    //     <img  draggable={false} src={logo} alt="" className="logo" />

    //     <div class="input-wrapper full-name">
    //       <label for="first">Full Name</label>
    //       <input type="text" />
    //     </div>

    //     <div class="input-wrapper occupation">
    //       <label for="first">Occupation</label>
    //       <input type="text" />
    //     </div>

    //     <div style={{marginTop:'80px',width:'max-content',margin: 'auto'}}>
    //       <div className='btn-div'>
    //         <butoon className="btn">
    //           Back
    //         </butoon>
    //         <button className="btn">
    //           Submit
    //         </button>
    //       </div>
    //     </div>

    //   </div>
    // </>
<>
<ToastContainer />

<div className="userAuthCenterFormBox">

<div className="userAuthCenterForm">
<div className={` ${direction == 'rtl' && ('rtl')}`}>

      <img  draggable={false} src={logo} alt="" className="logo" />
      <span className="border-middle"></span>
        {/* <input type="text" onChange={(e) => setFullName(e.target.value)} placeholder={t("auth.fullname")}  /> */}
        <Grid
        sx={{
          display:"flex",
          flexDirection:"column",
          gap:"24px"
        }}
        >
        <TextField
          fullWidth
          type="text"
            id="outlined-basic"
            label={t("auth.fullname")}
            variant="outlined"
            onChange={(e) => setFullName(e.target.value)}
            InputLabelProps={{
              style:{
                backgroundColor:"transparent",
                color:"#2a5673"
              }
            }}
            sx={{

              '& label.Mui-focused': {
                color: '#2a5673',
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#2a5673 !important",
                borderRadius: "8px !important",
            },
            "& input:-webkit-autofill": {
              WebkitBoxShadow: "0 0 0 1000px var(--white-color) inset",
              transition: "background-color 5000s ease-in-out 0s",
              background:"transparent"
          },

            }}

            />
            <TextField
          fullWidth
          type="text"
            id="outlined-basic"
            label={t("auth.occupation")}
            variant="outlined"
            onChange={(e) => setOccupation(e.target.value)}
            InputLabelProps={{
              style:{
                backgroundColor:"transparent",
                color:"#2a5673"

              }
            }}
            sx={{
              '& label.Mui-focused': {
                color: '#2a5673',
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#2a5673 !important",
                borderRadius: "8px !important",
            },
            }}

                        />

{loading ?(
  <div
  style={{
    display:"flex",
    justifyContent:"center"
  }}
  >
    <SingleLoading/>
  </div>
        ):
        <div className="btn-div">

        <button className="btn" onClick={updateUser}>{t("auth.button")}</button>
        </div>

      }
        </Grid>


        {/* <input type="text" onChange={(e) => setOccupation(e.target.value)} placeholder={t("auth.occupation")} /> */}
      {/* {
        !access &&
      <p className='error-text'>Full Name and Occupation must be provided</p>
      } */}

    </div>
</div>
</div>
</>


  )
}

export default UserAuth
