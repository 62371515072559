import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
// it is used to make perfect forms with validations
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import logo from "../../assets/logo.png";
import { LanguageDirection } from "../../utility/LanguageDirection";
import Text from "../Text/Text";
import Title from "../Title/Title";
import "./Login.css";
import {useSendOTPMutation} from "../../features/auth/authSlice";
import {useNavigate} from "react-router-dom";
import { SingleLoading } from "../LoadingPage/LoadingPage";

// async function loginUser() {

//   return axios
//     .get("https://mocki.io/v1/40574066-9e91-44de-a45f-6d9b7507a3db")
//     .then((response) => {
//       console.log("response.>>>>>>>>>>>>>", typeof response.data);
//       return response.data;
//     })
//     .catch((error) => {
//       console.log("error>>>>>>>>>>>>>>>>", error);
//     });
// }

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const Login = () => {
  // fetch Translation files
  const { t } = useTranslation();
  const navigate = useNavigate() ;
  const direction = LanguageDirection();
  const [validPhone , setValidPhone] = useState(true);
  const [phone, setPhone] = useState();
  const [loading,setLoading]=useState(false)
  const [sendOTP]  = useSendOTPMutation();



  const FolderSchema = yup.object().shape({
    phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').max(11, "Must contain 10 numbers").min(11, "Must contain 10 numbers")
  })

  const defaultValues = {
    phone: null
  }

  const methods = useForm({
    resolver: yupResolver(FolderSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;






  const handleMobileNumberChange = (e) => {
      const value = e.target.value;
    setValidPhone(true);

    var letters = /^[A-Za-z]+$/;
    if (e.target.value.match(letters)) {
      e.target.value = ''
      return
    }


    // const numberRegex = /^(05\d{8})$/;
    // setValidPhone(numberRegex.test(value))

    // if(numberRegex.test(value)){
    //     setPhone(value)
    // }else{
    //     return
    // }


    setPhone(e.target.value)

  }

  async function loginUser(e) {
    e.preventDefault();


    const numberRegex = /^(05\d{8})$/;
    if(!numberRegex.test(phone)){
        setValidPhone(numberRegex.test(phone))
        return;
    }


    if(!validPhone ){
        setValidPhone(false);
        setPhone('');
        return;
    }
    if(!phone){
        setValidPhone(false);
        return;
    }
    setLoading(true)


    const resp = await sendOTP({phone}) ;
    if(resp.data.success){
      navigate(`/otp?phone=${phone}`, {state: resp.data.otpId})
    }
  }


console.log(phone)


  // const navigate = useNavigate();
  return (
    <div className="loginFormCenter">

    <section className={`login ${direction == 'rtl' && ('rtl')}`}>
      <form className="login-content" onSubmit={loginUser} dir={direction}>
        <img  draggable={false} src={logo} alt="" className="logo" />
        <span className="border-middle"></span>
        <Title>{t("login.login")}!</Title>
        <div className="single-input">
          <input type="tell" placeholder="05XXXXXXXX"
            maxLength={10}
            // minLength={10}
            value={phone}
            onChange={handleMobileNumberChange}
          />
          {!validPhone &&
        <Text className='err-text'>
          {t("login.error_text")}
        </Text>
          }

        </div>
        <Text>
          {t("login.text")}
        </Text>

{loading ? (
  <div
  style={{
    display:"flex",
    justifyContent:"center"
  }}
  >
{/* <CircularProgress
   style={{
     color:"#2a5673",

   }}></CircularProgress> */}
   <SingleLoading/>
  </div>

):(
  <button disabled={ !phone } type="submit">

   {t("login.button")}


  </button>
)}



      </form>
    </section>
    </div>

  );
};

export default Login;
