import React, { useState } from "react";
import { LanguageDirection } from "../../utility/LanguageDirection";
import Title from "../Title/Title";
import './ShowConfirmRequest.css';
import {
    useGetLoyaltyProgramRedemptionReqByIdQuery,
    usePostLoyaltyProgramRedemptionMutation
} from "../../features/loyalityProgram/loyalitySlice";
import {useTranslation} from "react-i18next";

const ShowConfirmRequest = ({setShowDetails, handleMakeDelivered,id}) => {
    const direction = LanguageDirection();
    const {data,isLoading} = useGetLoyaltyProgramRedemptionReqByIdQuery(id);
    const [updateRequest] = usePostLoyaltyProgramRedemptionMutation();

    const [isFocused, setIsFocused] = useState(false);
    const [selectedOption, setSelectedOption] = useState(data?.deliveredBy[0]?.id || "");

    const {t} = useTranslation();
    const handleSelectFocus = () => {
      setIsFocused(true);
    };

    const handleSelectBlur = () => {
      setIsFocused(false);
    };

    const handleSelectChange = (event) => {
      setSelectedOption(event.target.value);
    };


    const handleSubmit = async () =>{
        // if(!selectedOption){
        //     return;
        // }
        console.log(selectedOption, 'selectedOption')
        const resp = await updateRequest({id,payload:{storeId: selectedOption}});
        setShowDetails(false);
        handleMakeDelivered()
    }
    return (
      <div
        className={`show-reward-details-component ${direction == 'rtl' && ('rtl')}`}
        onClick={() => setShowDetails(false)}

      >
        <div className="reward-container" onClick={(e) => e.stopPropagation()}>
          <button
            className="back-button"
            onClick={() => setShowDetails(false)}
          ></button>

          {/* <img  draggable={false} src={largeImage} alt="" /> */}
          <Title>Are you sure to make the order as delivered?</Title>
          <br /><br />
          <Title>Who delivered this item?</Title>

          <div>
              {
                  isLoading ?            <div className="single-request-skeleton">
                      <div className="skeleton skeleton-img"></div>
                      <div className="request-texts">
                          <div className="skeleton text"></div>
                          <div className="skeleton text2"></div>
                      </div>
                      <div className="skeleton btns"></div>
                  </div>
                :

                      <div className={`select-container ${isFocused ? 'focused' : ''}`}>
                          <select
                              className={`custom-select ${selectedOption ? 'has-value' : ''}`}
                              onFocus={handleSelectFocus}
                              onBlur={handleSelectBlur}
                              onChange={(e) => {handleSelectChange(e)}}
                          >
                            <option value="" disabled selected>{t("requests.select")}</option>
                              {
                                  data?.deliveredBy?.map((item, index) =>(
                                      <option key={index} value={item.id} onClick={() => setSelectedOption(item.id)}>{item.name}</option>
                                  ))
                              }

                          </select>
                      </div>

              }
          </div>

          <div className="redeem-button">
            <button onClick={handleSubmit}>
              Yes
            </button>

            <button onClick={() => setShowDetails(false)}>
              No
            </button>
          </div>
        </div>
      </div>
    );
};

export default ShowConfirmRequest;
